.pageBored{
  background-color: #c300b3;
  width: 100%;
  height: 100%;
  position: absolute;
  border: 5px solid #6f0085;
  box-sizing: border-box;
}
#answer{
  visibility: hidden;
  background-color: rgb(255, 81, 214);
  height: 20%;
}


@media only screen and (max-width: 768px) {
    .button {
      display: inline-block;
      padding: 10px 20px;
      font-size: 20px;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      outline: none;
      color: #00e0d8;
      background-color: #9f28f4;
      border: none;
      border-radius: 10px;
      box-shadow: 0 9px #1c1c1c;
    }
    .buttonContainer {
      position: absolute;
      z-index: 1; 
    }
    .button:hover {background-color: #8d50d9}
    .button:active {
      background-color: #9f28f4;
      box-shadow: 0 5px #666;
      transform: translateY(4px);
    }
    #homeButton{
      width: 100px;
      opacity: 100%;
      margin: 10px;
    }




    #boredBtn{
      position: absolute;
      top: 35%;
      left: 50%; 
      transform: translateX(-50%); 
      width: 200px;
      height: 200px;
    }
    #aloneBtn{
      visibility: hidden;
      position: absolute;
      top: 35%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #glBtn{
      visibility: hidden;
      position: absolute;
      top: 55%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #outsideBtn{
      visibility: hidden;
      position: absolute;
      top: 55%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #insideBtn{
      visibility: hidden;
      position: absolute;
      top: 35%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #winterBtn{
      visibility: hidden;
      position: absolute;
      top: 55%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #summerBtn{
      visibility: hidden;
      position: absolute;
      top: 35%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #DateBtn{
      visibility: hidden;
      position: absolute;
      top: 45%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #reloadBtn{
      visibility: hidden;
      position: absolute;
      top: 75%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #answer{
      position: absolute;
      top: 35%;
      left: 50%; 
      transform: translateX(-50%); 
      width: 50%;
    }

    

  }
  
  
  @media only screen and (min-width: 768px) {

    .ButtonList{
      position: absolute;
      top: 50%;
    }
    .button {
      display: inline-block;
      padding: 10px 20px;
      font-size: 20px;
      cursor: pointer;
      text-align: center;
      text-decoration: none;
      outline: none;
      color: #00e0d8;
      background-color: #9f28f4;
      border: none;
      border-radius: 10px;
      box-shadow: 0 9px #1c1c1c;
    }
    .buttonContainer {
      position: absolute;
      z-index: 1;
    }
    .button:hover {background-color: #8d50d9}
    .button:active {
      background-color: #9f28f4;
      box-shadow: 0 5px #666;
      transform: translateY(4px);
    }
    #homeButton{
      width: 100px;
      opacity: 100%;
      margin: 10px;
    }


    #boredBtn{
      position: absolute;
      top: -600%;
      left: 50%; 
      height: 700%;
      transform: translateX(-50%); 
      width: 200px;
    }
    #aloneBtn{
      position: absolute;
      visibility: hidden;
      top: -400%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #glBtn{
      position: absolute;
      visibility: hidden;
      left: 50%; 
      transform: translateX(-50%); 
      width: 150px;
    }
    #outsideBtn{
      position: absolute;
      visibility: hidden;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #insideBtn{
      position: absolute;
      visibility: hidden;
      top: -400%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #winterBtn{
      position: absolute;
      visibility: hidden;
      top: -400%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #summerBtn{
      position: absolute;
      visibility: hidden;
      left: 50%; 
      transform: translateX(-50%); 
    }
    #reloadBtn{
      position: absolute;
      visibility: hidden;
      top: 800%;
      left: 50%; 
      transform: translateX(-50%); 
    }
    .ButtonList{
      position: absolute;
      left: 50%;
      transform: translateX(-50%); 

    }
    #DateBtn{
      position: absolute;
      visibility: hidden;
      left: 50%;
      top: 400%; 
      transform: translateX(-50%); 
    }


  }
