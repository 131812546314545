
@media only screen and (max-width: 768px) {
  .pageSilly {
    margin: 0;
    padding: 0;
  }
  .Silly{
    background-color: #9f28f4;
    margin: 0;
    padding: 0;
  }
  .button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #00e0d8;
    background-color: #9f28f4;
    border: none;
    border-radius: 10px;
    box-shadow: 0 9px #1c1c1c;
  }

  .buttonContainer {
    position: absolute;
    z-index: 1; 
  }
  .button:hover {background-color: #8d50d9}

  .button:active {
    background-color: #9f28f4;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  #homeButton{
    width: 100px;
    opacity: 100%;
    margin: 10px;
  }
  .videoTag1 {
    position: relative;
    width: 100%;
    height: auto;
    background-image: url("/public/images/photo77.mp4");
    background-repeat: repeat-y;
    background-size: cover;

  }
  .videoTag2 {
    position: relative;
    width: 100%;
    height: auto;
    background-image: url("/public/images/photo77.mp4");
    background-repeat: repeat-y;
    background-size: cover;
  }
  .videoTag3 {
    position: relative;
    width: 100%;
    height: auto;
    background-image: url("/public/images/photo77.mp4");
    background-repeat: repeat-y;
    background-size: cover;
  }
  .videoTag4 {
    position: relative;
    width: 100%;
    height: auto;
    background-image: url("/public/images/photo77.mp4");
    background-repeat: repeat-y;
    background-size: cover;
  }

  img {
    display: block;
    width: 50%;
    height: auto;
    max-width: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: bounce 4s infinite;
  }

  @keyframes bounce {
    0% {
      transform: translate(-50%, -50%) scale(2);
    }
    50% {
      transform: translate(-50%, -50%) scale(5);
    }
    100% {
      transform: translate(-50%, -50%) scale(2);
    }
  }

}


@media only screen and (min-width: 768px) {
  .pageSilly {
    margin: 0;
    padding: 0;
  }
  .button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 20px;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    outline: none;
    color: #00e0d8;
    background-color: #9f28f4;
    border: none;
    border-radius: 10px;
    box-shadow: 0 9px #1c1c1c;
  }

  .buttonContainer {
    position: absolute;
    z-index: 1;
  }
  .button:hover {background-color: #8d50d9}

  .button:active {
    background-color: #9f28f4;
    box-shadow: 0 5px #666;
    transform: translateY(4px);
  }

  #homeButton{
    width: 100px;
    opacity: 100%;
    margin: 10px;
  }
  .videoTag1 {
    position: fixed;
    width: 100%;
    height: auto;
    max-width: 100%;
    margin: 0 auto;
    opacity: 100%;
  }
  .videoTag2 {
    visibility: hidden; 
  }
  .videoTag3 {
    visibility: hidden; 

  }
  .videoTag4 {
    visibility: hidden; 

  }


  @keyframes bounce {
    0% {
      transform: translate(-50%, -50%) scale(1);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }

  img {
    display: block;
    width: 50%;
    height: auto;
    max-width: 20%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: bounce 4s infinite;
  }
}